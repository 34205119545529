import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import index from './view/index/index.jsx'
import indexPad from './view/index/indexPad.jsx'
import { AutoCenter } from 'antd-mobile'

class App extends Component {
  render() {
    return (
      <AutoCenter>
        <Router>
          <div>
            <Switch>
              <Route exact path="/index" component={index} />
              <Route exact path="/app/47ec2dd791e31e2ef2076caf64ed9b3d" component={indexPad} />
              <Redirect from="/" to="/index" />
            </Switch>
          </div>
        </Router>
      </AutoCenter>
    )
  }
}

export default App
