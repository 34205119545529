// 'use strict';
import React, { Component } from 'react';
import { Swiper,Toast, Button } from 'antd-mobile';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './assets/index.module.scss';

// const print = function (value) {};
const images = [
'https://aichat.showlearn.xyz/docs/static/c1.png',
'https://aichat.showlearn.xyz/docs/static/c2.png',
'https://aichat.showlearn.xyz/docs/static/c3.png',
'https://aichat.showlearn.xyz/docs/static/c4.png'];
const items = images.map((uri, index) => (
  <Swiper.Item key={index}>
    <div
      className={`${styles['box-item']} flex-col`}
      style={{ backgroundImage: 'url('+uri+')' }}
      onClick={() => {
        // Toast.show(`你点击了卡片 ${index + 1}`)
      }}
      >
    </div>
  </Swiper.Item>
));
function getQueryParam(name) {  
  const search = window.location.search;  
  const params = new URLSearchParams(search);  
  return params.get(name);  
}  



class DDS_lanhupage_0 extends Component {
  state = {
    showInvite: false,
    proxyCode:getQueryParam('pt') || '',
    info: {},
  };
  // searchParams = useSearchParams();
  constructor(prpos) {
    super(prpos);
    // const [searchParams] = useSearchParams();
    console.log('constor',getQueryParam('pt'));
  }
  componentDidMount() {
    console.log('on mount',this,this.state);
    console.log('show invite',this.state)
  };
   handleCopy = async() => {
    try {
      // if(navigator.clipboard && navigator.clipboard.writeText) {
      //   await navigator.clipboard.writeText(this.state.proxyCode || '0000');
      // }
      // setIsCopied(true);
      Toast.show(`邀请码复制成功`);
      // 可以添加一些UI状态反馈，例如显示"已复制"
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  handAppDownLoad = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = this.state.info.upadteUrl || 'https://aichat.showlearn.xyz/apks/latest/aichat-release.apk';
    downloadLink.download = this.state.info.fileName || 'ai-chat-release.apk';
    downloadLink.click();
  }
  render() {
    return (
      <div className={`${styles.page} flex-col r-view`}>
        {/* <img
          className={`${styles['image_1']}`}
          src={
            require('./assets/img/SketchPng7756d5ccbe4599ed35ee39d22c1b8caea09d90b605a781786268e14ef0333cf5.png')
          }
        /> */}
        <div className={`${styles['section_1']} flex-col`}>
          <div className={`${styles['box_1']} flex-row justify-between`}>
            <div className={`${styles['group_1']} flex-col`}>
              <div className={`${styles['box_2']} flex-col r-app-icon`} />
            </div>
            <div className={`${styles['text-group_1']} flex-col`}>
              <span className={`${styles['text_1']}`}>上知AI</span>
              <span className={`${styles['text_2']}`}>功能强大的人工智能对话系统</span>
            </div>
          </div>
          <Button className={`${styles['text-wrapper_1']} flex-col  r-btn`}
            onClick={this.handAppDownLoad}
          ><span className={`${styles['text_3']}`}>下载</span></Button>
          {/* <div className={`${styles['text-wrapper_1']} flex-col`}>
            
          </div> */}
          {!!this.state.proxyCode ? 
          <div className={`${styles['text-wrapper_2']} flex-row`} >
            <span className={`${styles['text_4']}`}>邀请码：</span>
            <span className={`${styles['text_5']}`}>{this.state.proxyCode}</span>
            {/* onClick={this.handleCopy}  */}
            <CopyToClipboard text={this.state.proxyCode} options={{message: '复制成功'}} >
              <span onClick={this.handleCopy} className={`${styles['text_6']}`}>
              复制
                {/* <span>复制</span> */}
              </span>
            </CopyToClipboard>
          </div>
          : <></>
          }
          
          <div className={`${styles['box_3']} flex-row r-content justify-between`}>
            <Swiper autoplay slideSize={53} trackOffset={0} loop stuckAtBoundary={false} className={`${styles['demo-chat-swiper']}`}>{items}</Swiper>
          </div>
          <span className={`${styles['text_7']}`}>应用介绍</span>
          <span className={`${styles['paragraph_1']}`}>
            【上知AI】是一款功能强大的人工智能对话系统。它具备高度精准的信息处理能力和精美的图片生成功能，能满足各种复杂的内容创作、文案要求、生成图片、难题解答等需求。
            <br />
            【上知AI】通过结合流畅的对话体验和卓越的智能分析，让用户在各种场景下均可轻松应对。
            <br />
          </span>
          <span className={`${styles['text_8']}`}>开发者：石家庄市智秒信息科技工作室</span>
          <span className={`${styles['text_9']}`}>备案号：冀ICP备2023033293号-2A</span>
        </div>
        {/* <img
          className={`${styles['image_2']}`}
          src={
            require('./assets/img/SketchPngcb87c7446186022d84e080a35d7c5af50494052e10c861da0099c52afc60848e.png')
          }
        /> */}
      </div>
    );
  }
}
export default DDS_lanhupage_0;
